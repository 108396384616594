var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"empresa-autocomplete",attrs:{"cache-items":"","items":_vm.empresas,"search-input":_vm.empresaAutocompleteInput,"item-text":"value","item-value":"id","outlined":"","dense":"","rules":_vm.empresaSelected === null ? _vm.rules.required : [],"clearable":"","autocomplete":"not","label":"Empresa","hint":"Nombre o CUIT","persistent-hint":"","append-icon":""},on:{"update:searchInput":function($event){_vm.empresaAutocompleteInput=$event},"update:search-input":function($event){_vm.empresaAutocompleteInput=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.value))]),_c('v-list-item-subtitle',[_vm._v("CUIT: "+_vm._s(item.cuit)+" ")])],1)]}},{key:"append",fn:function(){return [(_vm.empresasLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"28","color":"primary"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.empresaSelected),callback:function ($$v) {_vm.empresaSelected=$$v},expression:"empresaSelected"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"dense":"","outlined":"","label":"Período desde","hint":"Formato AAAAMM","persistent-hint":"","type":"text","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                  [_vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoDesde),callback:function ($$v) {_vm.periodoDesde=$$v},expression:"periodoDesde"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"dense":"","outlined":"","label":"Período hasta","type":"text","hint":"Formato AAAAMM","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","persistent-hint":"","rules":_vm.rules.required.concat(
                  [_vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoHasta),callback:function ($$v) {_vm.periodoHasta=$$v},expression:"periodoHasta"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }