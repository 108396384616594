<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="tablasRemuneraciones"
          class="elevation-1"
          :loading="loading"
          show-expand
          :expanded.sync="expanded"
          :search="search"
          item-key="minimoPrevId"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.minimoPrevPorDefecto`]="{ item }">
            <v-icon v-if="item.minimoPrevPorDefecto" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canOpenRelacionRemEmpresas">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalRelacionRemEmpresas(item)"
                >
                  {{ empresaIcon }}
                </v-icon>
              </template>
              <span>Empresas</span>
            </v-tooltip>
            <v-tooltip left v-if="canOpenRemuneracionesMinimas">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalRemuneracionesMinimas(item)"
                >
                  {{ moneyIcon }}
                </v-icon>
              </template>
              <span>Remuneraciones mínimas</span>
            </v-tooltip>
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar registro</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteConceptoAFIP(item.minimoPrevId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar registro</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fechaAlta }}
              <strong style="padding-left: 100px">Usuario alta:</strong>
              {{ item.usuAlta }}
              <strong style="padding-left: 100px">Fecha modificación:</strong>
              {{ item.fechaModi }}
              <strong style="padding-left: 100px">Usuario modificación:</strong>
              {{ item.usuModi }}
            </td>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="30rem"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditTablaRemuneracionMinima
        :tablaRemuneracion="tablaRemuneracion"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <v-dialog
      v-if="modalRemuneracionesMinimas"
      v-model="modalRemuneracionesMinimas"
      max-width="60rem"
      @keydown.esc="closeAndReload"
      persistent
    >
      <RemuneracionesMinimas
        :tablaRemuneracion="tablaRemuneracion"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <v-dialog
      v-if="modalRelacionRemEmpresas"
      v-model="modalRelacionRemEmpresas"
      max-width="60rem"
      @keydown.esc="closeAndReload"
      persistent
    >
      <RemuneracionesEmpresas
        :tablaRemuneracion="tablaRemuneracion"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <DeleteDialog
      :maxWidth="maxWidthDeleteModal"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import EditTablaRemuneracionMinima from "@/components/modules/cuotas/aportes/EditTablaRemuneracionMinima.vue";
import RemuneracionesMinimas from "@/components/modules/cuotas/aportes/RemuneracionesMinimas.vue";
import RemuneracionesEmpresas from "@/components/modules/cuotas/aportes/RemuneracionesEmpresas.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "TablasRemuneracionesMinimas",
  components: {
    PageHeader,
    GoBackBtn,
    DeleteDialog,
    EditTablaRemuneracionMinima,
    RemuneracionesMinimas,
    RemuneracionesEmpresas,
    Ayuda
  },
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    checkIcon: enums.icons.CHECK_OUTLINE,
    empresaIcon: enums.icons.BUSINESS,
    moneyIcon: enums.icons.MONEY,
    showDeleteModal: false,
    modalRemuneracionesMinimas: false,
    routeToGo: "ConfiguracionDDJJAportes",
    titleDelete: "",
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.TABLAS_REMUNERACIONES_MINIMAS,
    search: "",
    tablasRemuneraciones: [],
    tablaRemuneracion: null,
    searchIcon: enums.icons.SEARCH,
    title: enums.titles.TABLAS_REMUNERACIONES_MINIMAS,
    rules: rules,
    expanded: [],
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "minimoPrevNom",
        sortable: false
      },
      {
        text: "Observaciones",
        align: "start",
        value: "minimoPrevObserva",
        sortable: false
      },
      {
        text: "Por defecto",
        align: "center",
        value: "minimoPrevPorDefecto",
        sortable: false,
        filterable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      { text: "", value: "data-table-expand", align: "end" }
    ],

    allowedActions: null,
    loading: false,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    canOpenRemuneracionesMinimas: false,
    canOpenRelacionRemEmpresas: false,
    openModalEdit: false,
    modalRelacionRemEmpresas: false,
    maxWidthDeleteModal: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadTablasRemuneracionesMinimas();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getTablasRemuneracionesMinimas: "aportes/getTablasRemuneracionesMinimas",
      getAllRemuneracionesMinimasById:
        "aportes/getAllRemuneracionesMinimasById",
      getRemuneracionesMinimasEmpresasById:
        "aportes/getRemuneracionesMinimasEmpresasById",
      deleteTablasRemuneracionesMinimas:
        "aportes/deleteTablasRemuneracionesMinimas"
    }),
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVA_TABLA_REMUNERACION_MINIMA:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_TABLA_REMUNERACION_MINIMA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_TABLA_REMUNERACION_MINIMA:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.REMUNERACIONES_MINIMAS:
            this.canOpenRemuneracionesMinimas = true;
            break;
          case enums.modules.adminSistema.permissions
            .RELACION_REMUNERACIONES_EMPRESAS:
            this.canOpenRelacionRemEmpresas = true;
            break;
          default:
            break;
        }
      });
    },
    async loadTablasRemuneracionesMinimas() {
      this.loading = true;
      const response = await this.getTablasRemuneracionesMinimas();
      if (response.status) {
        this.loading = false;
        this.tablasRemuneraciones = response.data.data;
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.tablaRemuneracion = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.modalRemuneracionesMinimas = false;
      this.modalRelacionRemEmpresas = false;
      this.loadTablasRemuneracionesMinimas();
    },
    closeModal() {
      this.openModalEdit = false;
    },
    async deleteConceptoAFIP(id) {
      const remMinimas = await this.getAllRemuneracionesMinimasById(id);
      const remEmpresas = await this.getRemuneracionesMinimasEmpresasById(id);

      if (remMinimas.data.data.length > 0 && remEmpresas.length > 0) {
        this.maxWidthDeleteModal = "37%";
        this.titleDelete = `Se eliminarán las remuneraciones y las<br> empresas relacionadas a esta tabla.<br> ¿Desea continuar?`;
      } else if (remMinimas.data.data.length > 0 && remEmpresas.length === 0) {
        this.maxWidthDeleteModal = "34%";
        this.titleDelete = `Se eliminarán las remuneraciones<br> relacionadas a esta tabla.<br> ¿Desea continuar?`;
      } else if (remMinimas.data.data.length === 0 && remEmpresas.length > 0) {
        this.maxWidthDeleteModal = "31%";
        this.titleDelete = `Se eliminarán las empresas<br> relacionadas a esta tabla.<br> ¿Desea continuar?`;
      } else {
        this.maxWidthDeleteModal = "20%";
        this.titleDelete = `¿Eliminar registro?`;
      }
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteTablasRemuneracionesMinimas(
        this.idToDelete
      );
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadTablasRemuneracionesMinimas();
      }
    },
    openModalRemuneracionesMinimas(item) {
      this.modalRemuneracionesMinimas = true;
      this.tablaRemuneracion = item;
    },
    openModalRelacionRemEmpresas(item) {
      this.modalRelacionRemEmpresas = true;
      this.tablaRemuneracion = item;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped></style>
