<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                :rules="rules.required.concat([rules.maxLength(nombre, 50)])"
                label="Nombre"
                v-model="nombre"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                v-model="observaciones"
                auto-grow
                outlined
                dense
                :rules="
                  rules.required.concat([rules.maxLength(observaciones, 1000)])
                "
                label="Observaciones"
                rows="1"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-switch
                class="mt-1"
                v-model="porDefecto"
                label="Por defecto"
                dense
                outlined
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditTablaRemuneracionMinima",
  props: {
    tablaRemuneracion: {
      type: Object,
      required: false,
    },
  },
  directives: { mask },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_TABLA_REMUNERACIONES_MINIMAS,
    formNewTitle: enums.titles.NUEVA_TABLA_REMUNERACIONES_MINIMAS,
    rules: rules,
    nombre: null,
    observaciones: null,
    porDefecto: false,
  }),
  created() {
    if (this.tablaRemuneracion != null) {
      this.setTablaRemuneracionMinima();
    } else {
      this.newTablaRemuneracionMinima();
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      postTablasRemuneracionesMinimas:
        "aportes/postTablasRemuneracionesMinimas",
    }),
    closeModal() {
      this.$emit("closeAndReload");
    },
    setTablaRemuneracionMinima() {
      this.nombre = this.tablaRemuneracion.minimoPrevNom;
      this.observaciones = this.tablaRemuneracion.minimoPrevObserva;
      this.porDefecto = this.tablaRemuneracion.minimoPrevPorDefecto;
    },
    async newTablaRemuneracionMinima() {
      this.formEditTitle = this.formNewTitle;
    },
    async saveEdit() {
      const data = {
        minimoPrevId:
          this.tablaRemuneracion != null
            ? this.tablaRemuneracion.minimoPrevId
            : 0,
        minimoPrevNom: this.nombre,
        minimoPrevObserva: this.observaciones,
        minimoPrevPorDefecto: this.porDefecto,
      };
      const response = await this.postTablasRemuneracionesMinimas(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
