<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Empresa -->
            <v-col cols="12" class="pb-0">
              <v-autocomplete
                ref="empresa-autocomplete"
                cache-items
                v-model="empresaSelected"
                :items="empresas"
                :search-input.sync="empresaAutocompleteInput"
                item-text="value"
                item-value="id"
                outlined
                dense
                :rules="empresaSelected === null ? rules.required : []"
                clearable
                autocomplete="not"
                label="Empresa"
                hint="Nombre o CUIT"
                persistent-hint
                append-icon
              >
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.value }}</v-list-item-title>
                    <v-list-item-subtitle
                      >CUIT: {{ item.cuit }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    size="28"
                    v-if="empresasLoading"
                    color="primary"
                  ></v-progress-circular>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- Periodo Desde -->
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                dense
                outlined
                v-model="periodoDesde"
                label="Período desde"
                hint="Formato AAAAMM"
                persistent-hint
                type="text"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <!-- Periodo Hasta -->
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                dense
                outlined
                v-model="periodoHasta"
                label="Período hasta"
                type="text"
                hint="Formato AAAAMM"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                persistent-hint
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditEmpresaRemuneracion",
  directives: { mask },
  props: {
    empresaRem: { type: Object, required: false },
    minimoPrevId: { type: Number, required: true },
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_RELACION_EMPRESA_REMUNERACION,
    newTitle: enums.titles.NUEVA_RELACION_EMPRESA_REMUNERACION,
    rules: rules,
    empresas: [],
    empresaSelected: null,
    empresaAutocompleteInput: null,
    periodoDesde: null,
    banderaEmpresaSelected: false,
    periodoHasta: null,
    empresasLoading: null,
  }),
  created() {
    if (this.empresaRem != null) {
      this.banderaEmpresaSelected = true;
      this.setEmpresaRemuneracion();
    } else {
      this.newEmpresaRemuneracion();
    }
  },
  watch: {
    empresaAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getEmpresasFilter();
      }
    },
  },
  methods: {
    ...mapActions({
      postRemuneracionesMinimasEmpresasById:
        "aportes/postRemuneracionesMinimasEmpresasById",
      fetchEmpresasByQuery: "aportes/fetchEmpresasByQuery",
      setAlert: "user/setAlert",
    }),
    setEmpresaRemuneracion() {
      this.empresaAutocompleteInput = this.empresaRem.empNom;
      this.periodoDesde = this.empresaRem.periodoDesde;
      this.periodoHasta = this.empresaRem.periodoHasta;
    },
    async newEmpresaRemuneracion() {
      this.formEditTitle = this.newTitle;
    },
    async saveEdit() {
      const data = {
        minimoPrevEmpresaId: this.empresaRem
          ? this.empresaRem.minimoPrevEmpresaId
          : 0,
        minimoPrevId: this.minimoPrevId,
        empId: this.empresaSelected,
        periodoDesde: this.periodoDesde,
        periodoHasta: this.periodoHasta,
      };
      const res = await this.postRemuneracionesMinimasEmpresasById(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },
    getEmpresasFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.empresas?.some(
            (x) => x.nombreCuit == this.empresaAutocompleteInput
          )
        ) {
          return;
        } else if (!this.banderaEmpresaSelected) {
          this.empresasLoading = true;
          const res = await this.fetchEmpresasByQuery({
            input: this.empresaAutocompleteInput,
          });
          this.$refs["empresa-autocomplete"].cachedItems = [];
          this.empresas = res;
          this.empresasLoading = false;
        } else {
          this.empresasLoading = true;
          const response = await this.fetchEmpresasByQuery({
            input: this.empresaRem.empCuil,
          });
          this.empresas = response;
          this.empresaSelected = response[0].id;
          // ^^ evita que se borre el campo empresaAutocompleteInput al setear los datos
          this.banderaEmpresaSelected = false;
          this.empresasLoading = false;
        }
      }, 100);
    },
    customFilter(item, queryText, itemText) {
      return (
        item.nombreCuit ||
        item.cuit.indexOf(queryText) > -1 ||
        item.value.indexOf(queryText) > -1
      );
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>
