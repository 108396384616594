<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Periodo Desde -->
            <v-col cols="12" sm="4" class="pb-0">
              <v-text-field
                dense
                outlined
                v-model="periodoDesde"
                label="Período desde"
                hint="Formato AAAAMM"
                persistent-hint
                type="text"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <!-- Periodo Hasta -->
            <v-col cols="12" sm="4" class="pb-0">
              <v-text-field
                dense
                outlined
                v-model="periodoHasta"
                label="Período hasta"
                type="text"
                hint="Formato AAAAMM"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                persistent-hint
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <currency-input
                v-model="importe"
                label="Importe"
                :options="currencyOptions"
                :rules="rules.required.concat([rules.maxLength(importe, 23)])"
              ></currency-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "EditRemuneracionMinima",
  directives: { mask },
  components: {  CurrencyInput },
  props: {
    remuneracionMinimaId: { type: Number, required: false },
    minimoPrevId: { type: Number, required: true },
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_REMUNERACION_MINIMA,
    newTitle: enums.titles.NUEVA_REMUNERACION_MINIMA,
    rules: rules,
    importe: null,
    periodoDesde: null,
    periodoHasta: null,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999,
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true,
    },
  }),
  created() {
    if (this.remuneracionMinimaId != null) {
      this.setRemuneracionMinima();
    } else {
      this.newRemuneracionMinima();
    }
  },
  methods: {
    ...mapActions({
      getOneRemuneracionMinimaById: "aportes/getOneRemuneracionMinimaById",
      postRemuneracionesMinimasById: "aportes/postRemuneracionesMinimasById",
      setAlert: "user/setAlert",
    }),
    async setRemuneracionMinima() {
      const response = await this.getOneRemuneracionMinimaById(this.remuneracionMinimaId);
      this.importe = response.importe;
      this.periodoDesde = response.periodoDesde;
      this.periodoHasta = response.periodoHasta;
    },
    async newRemuneracionMinima() {
      this.formEditTitle = this.newTitle;
    },
    async saveEdit() {
      const data = {
        minimoPrevDetaId: this.remuneracionMinimaId,
        minimoPrevId: this.minimoPrevId,
        importe: this.importe,
        periodoDesde: this.periodoDesde,
        periodoHasta: this.periodoHasta,
      };
      const res = await this.postRemuneracionesMinimasById(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },

    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>
