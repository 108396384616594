<template>
  <v-card>
    <v-container fluid>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="headers"
            item-key="minimoPrevEmpresaId"
            hide-default-footer
            :items="empresas"
            class="elevation-1"
            show-expand
            :expanded.sync="expanded"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row class="pb-0">
                  <v-col cols="12" md="10" align="start">
                    <v-card-title class="pl-1 primary--text"
                      >{{ title }}
                      <br />
                      {{ tablaTitle }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-8 text-right">
                    <v-btn color="primary" @click="openModal()"> Nuevo </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <!-- Acciones -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar registro</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteEmpresaRemuneracion(item.minimoPrevEmpresaId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar registro</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fechaAlta }}
                <strong style="padding-left:50px;">Usuario alta:</strong>
                {{ item.usuAlta }}
                <strong style="padding-left:50px;">Fecha modificación:</strong>
                {{ item.fechaModi }}
                <strong style="padding-left:50px;">Usuario modificación:</strong>
                {{ item.usuModi }}
              </td>
            </template>
          </v-data-table>
          <v-dialog
            v-if="openModalEdit"
            v-model="openModalEdit"
            max-width="30%"
            @keydown.esc="openModal()"
            persistent
          >
            <EditEmpresaRemuneracion
              :minimoPrevId="minimoPrevId"
              :empresaRem="empresaRem"
              @closeAndReload="closeAndReloadModal"
            />
          </v-dialog>
          <v-row>
            <v-col cols="12" class="text-right" align="end">
              <v-btn outlined @click="closeModal"> Cerrar </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
    <DeleteDialog
      :maxWidth="'20%'"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import DeleteDialog from "@/components/shared/DeleteDialog";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import EditEmpresaRemuneracion from "@/components/modules/cuotas/aportes/EditEmpresaRemuneracion.vue";

export default {
  components: { DeleteDialog, EditEmpresaRemuneracion },
  name: "RemuneracionesEmpresas",
  props: {
    tablaRemuneracion: { type: Object, required: true, default: null }
  },
  data() {
    return {
      title: "Empresas de la tabla:",
      tablaTitle: `${this.tablaRemuneracion.minimoPrevNom}`,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      rules: rules,
      empresas: [],
      empresaRem: null,
      titleDelete: "¿Eliminar registro?",
      showDeleteModal: false,
      idToDelete: null,
      openModalEdit: false,
      expanded: [],
      minimoPrevId: null,
      headers: [
        {
          text: "Empresa",
          align: "start",
          sortable: false,
          value: "empNom"
        },
        {
          text: "Período desde",
          sortable: false,
          value: "periodoDesde"
        },
        {
          text: "Período hasta",
          sortable: false,
          value: "periodoHasta"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        { text: "", value: "data-table-expand", align: "end" }
      ]
    };
  },
  created() {
    this.setEmpresasRemuneraciones();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getRemuneracionesMinimasEmpresasById:
        "aportes/getRemuneracionesMinimasEmpresasById",
      deleteRemuneracionesMinimasEmpresasById:
        "aportes/deleteRemuneracionesMinimasEmpresasById"
    }),
    deleteEmpresaRemuneracion(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    async confirmDelete() {
      const response = await this.deleteRemuneracionesMinimasEmpresasById(
        this.idToDelete
      );
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setEmpresasRemuneraciones();
      }
    },
    async setEmpresasRemuneraciones() {
      this.minimoPrevId = this.tablaRemuneracion
        ? this.tablaRemuneracion.minimoPrevId
        : this.minimoPrevId;
      const data = await this.getRemuneracionesMinimasEmpresasById(
        this.tablaRemuneracion.minimoPrevId
      );
      this.empresas = data;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    openModal(item) {
      this.openModalEdit = true;
      this.minimoPrevId = this.tablaRemuneracion.minimoPrevId;
      this.empresaRem = item;
    },
    closeAndReloadModal() {
      this.openModalEdit = false;
      this.setEmpresasRemuneraciones();
    }
  }
};
</script>

<style scoped></style>
